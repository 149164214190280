@mixin mobile-sm {
  @media (max-width: 429px) {
    @content;
  }
}

// Tablets (tablets, 768px)
@mixin tablets {
  @media (min-width: 768px) {
    @content;
  }
}

// Landscape tablets (tablets, laptops, 1024px)
@mixin tablets-lg {
  @media (min-width: 1024px) {
    @content;
  }
}

// Large devices (desktops, 1280px)
@mixin desktops {
  @media (min-width: 1280px) {
    @content;
  }
}

// Large devices (desktops full-HD, 1920px)
@mixin desktops-lg {
  @media (min-width: 1920px) {
    @content;
  }
}
